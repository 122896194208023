import React from 'react'
import Helmet from 'react-helmet'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset-advanced'
import { ModalProvider, BaseModalBackground } from 'styled-react-modal'

const theme = {
  primaryColor: '#008332',
  secondaryColor: '#919191',
  colorWhite: '#fff',
  colorBlack: '#000',
  colorGreen: '#008132',
  textColor: '#1a1715',
  backgroundColor: '#e8e8e8',
  fontMetaProBook: `'MetaPro-Book', sans-serif`,
  fontMetaPro: `'MetaPro', sans-serif`,
}

const ModalBackground = styled(BaseModalBackground)`
  z-index: 200;
`

const GlobalStyle = createGlobalStyle`
  ${reset};

  body {
    background-color: ${(props) => props.theme.backgroundColor};
    font-family: 'MetaPro-Book', 'Helvetica Neue', 'Helvetica', sans-serif;
    font-weight: 400;
  }
`

export default ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Helmet>
      <title>Zostań kierowcą na medal - Schaeffler REPXPERT</title>

      <meta property="og:title" content="Zostań kierowcą na medal!" />
      <meta
        property="og:description"
        content="Kupuj nasze marki, odbieraj gwarantowane nagrody z limitowanej edycji, sprawdź się na torze z Tomaszem Kucharem i wiele więcej! Łączna wartość nagród to ponad 250 000 zł!"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.kierowcanamedal.pl" />
      <meta
        property="og:image:url"
        content={`https://www.kierowcanamedal.pl${require('../images/og-image.png')}`}
      />
      <meta property="og:image:width" content="2076" />
      <meta property="og:image:height" content="1256" />
      <link rel="stylesheet" href="https://use.typekit.net/iwx8csu.css" />
    </Helmet>

    <ModalProvider backgroundComponent={ModalBackground}>
      {children}
    </ModalProvider>
  </ThemeProvider>
)
